export function numericStringMask(str, mask) {
    if (!mask) return str;
    if (str.length === 0) return "";

    const numeric = str.replaceAll(/\D/g, '');


    let idx = 0;

    const formatted = mask.split('').map(strItem => {
        if (strItem === '#') {
            strItem = numeric[idx];
            idx++;
        }
        return strItem;
    });

    return formatted.join('');
}

export function createNonUSPhoneInputMask(phoneNum) {
    let mask = '';

    if (phoneNum.length > 4) {
        mask = "+# (###) ###";
    }

    if (phoneNum.length > 7) {
        mask = "+# (###) ###-####"
    }

    if (phoneNum.length === 12) {
        mask = '+## (###) ###-####';
    }


    return mask;
}

export function createPhoneInputMask(phoneNum) {
    let mask = '';
    if (phoneNum.length > 3) {
        mask += '(###) ###';
    }

    if (phoneNum.length > 6) {
        mask += '-####';
    }
    return mask;
}

export function createCdnInputMask(cdn) {
    let mask = '##';
    if (cdn.length > 2) {
        mask += '-####';
    }

    if (cdn.length > 6) {
        mask += '-###'
    }

    return mask;
}