<template>
    <h1 style="opacity: 1; color: black;">{{ headerText }}</h1>
    <div v-if="editingExisting">
        <h3 style="opacity: 1; color: black;">Currently Editing: </h3>
        <h5 style="opacity: 1; color: black;">{{ oldDistrictVals.districtName }}</h5>
    </div>
    <div v-if="message['existsMessage']">
        <h4><span>{{ message['existsMessage'] }}</span></h4>
    </div>
    <form @submit.prevent="submitForm">
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>CDN<span>*</span></label>
                <input tabindex="1" class="form-control" v-model="district.districtCdn" id="districtCdn" placeholder="CDN"
                       required
                       :disabled="editingExisting">
            </div>
            <div class="col-4">
                <label>District Name<span>*</span></label>
                <input class="form-control" type="text" v-model="district.districtName" id="districtName"
                       placeholder="District Name"
                       required :disabled="!showFullForm"
                tabindex="5"/>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>Phone Number<span>*</span></label>
                <input tabindex="2" class="form-control" v-model="district.districtPhoneNumber" id="districtPhoneNumber"
                       placeholder="Phone Number"
                       required
                       :disabled="!showFullForm"/>
            </div>
            <div class="col-4">
                <label>Primary District Address<span>*</span></label>
                <input class="form-control" type="text" v-model="district.primaryDistrictAddress"
                       id="primaryAddress"
                       placeholder="Primary Address"
                       required :disabled="!showFullForm"
                tabindex="6"/>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <label>Fax Number</label>
                <input tabindex="3" class="form-control" v-model="district.districtFaxNumber" id="districtFaxNumber"
                       placeholder="Fax Number" :disabled="!showFullForm"/>
            </div>
            <div class="col-4">
                <label>Secondary District Address </label>
                <input class="form-control" type="text" v-model="district.secondaryDistrictAddress"
                       id="secondaryAddress"
                       placeholder="Secondary Address (ex. PO Box)" :disabled="!showFullForm"
                tabindex="7">
            </div>
            <div class="col-2"></div>
        </div>

        <div class="row">
            <div class="col-2"></div>
            <div class="col-4">
                <EsuSelectComposition
                        :show-school="false"
                        :show-district="false"
                        :id-esu="district.idEsu !== null ? district.idEsu : -1"
                        @selectedEsu="changeEsu($event)"
                        :esu-disabled="esuDisabled"
                        :tab-idx="4"
                />
            </div>
            <div class="col-4">
                <label>City<span>*</span></label>
                <input class="form-control" v-model="district.districtCity" id="City" placeholder="City" required
                       :disabled="!showFullForm"
                tabindex="8"/>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-4"></div>
            <div class="col-4">
                <label>Zip Code<span>*</span></label>
                <input class="form-control" v-model="district.districtZipCode" id="districtZipCode"
                       placeholder="Zip Code" required :disabled="!showFullForm"
                tabindex="9"/>
            </div>
            <div class="col-2"></div>
        </div>
        <div>
            <button class="button btn-primary" type="submit btn-primary" :disabled="!canSubmit">{{
                submitText
                }}
            </button>
        </div>
    </form>
    <hr/>
    <div v-if="editingExisting" class="container mt-2" style="border: 1px solid black; width: 75%;">

        <div class="row" style="padding: 1%;">
            <h5 style="font-weight: bold; color: black;">Delete District</h5>
            <div class="col-sm" style="text-align: left; margin-top: 30px">
                <h6>{{ dangerZoneMessage }}</h6>
            </div>
            <div class="col-sm">
                <button :class="canDelete ? `button btn-danger bi-trash-fill` : `button btn-danger bi-trash-fill` "
                        :disabled="!canDelete" @click="showDeleteModal = true">Delete
                </button>
            </div>
        </div>
    </div>
    <div v-if="showDeleteModal">
        <Teleport to="body">
            <OrgDeleteModal
                    :org-data="district"
                    :show="showDeleteModal"
                    :org-type="`District`"
                    @close="showDeleteModal = false"
                    @deleteCall="deleteHelper"/>
        </Teleport>
    </div>

</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {API_URL, ZIP_CODE_REGEX} from "../../../../../Constants";
import axios from "axios";
import EsuSelectComposition from "@/components/LocationSelect/EsuSelectComposition";
import {useStore} from "vuex";
import Swal from "sweetalert2";
import {createCdnInputMask, createPhoneInputMask, numericStringMask} from "@/InputMaskFunctions";
import {isEqual} from "lodash/lang";
import router from "@/router";
import OrgDeleteModal from "@/components/Merge/OrgDeleteModal.vue";
import {deleteDistrict} from "@/Helpers/ApiCalls/DistrictAPICalls";


export default {
	name: "DistrictForm",
	components: {OrgDeleteModal, EsuSelectComposition},
	props: {
		idDistrict: Number,
		submitText: String,
		editingExisting: Boolean,
		headerText: String
	},
	setup(props, context) {
		const store = useStore();
		const authToken = computed(() => store.getters['auth/authenticated']);
		let district = ref({});
		let oldDistrictVals = ref({});
		let exists = ref(true);
		let message = ref({});
		const showDeleteModal = ref(false);

		onBeforeMount(() => {
			if (props.idDistrict !== undefined && props.idDistrict !== null) {
				getDistrict();
			}
		})

		watch(() => district.value.districtZipCode, () => {
			if (!ZIP_CODE_REGEX.test(district.value.districtZipCode)) {
				district.value.districtZipCode = district.value.districtZipCode.substring(0, district.value.districtZipCode.length - 1);
			}
			if (district.value.districtZipCode.length > 5) {
				district.value.districtZipCode = district.value.districtZipCode.substring(0, district.value.districtZipCode.length - 1);
			}
		})

		watch(() => district.value.districtPhoneNumber, () => {
			const numeric = district.value.districtPhoneNumber.replaceAll(/\D/g, '');
			let mask = createPhoneInputMask(numeric);
			district.value.districtPhoneNumber = numericStringMask(numeric, mask);
		})

		watch(() => district.value.districtFaxNumber, () => {
			const numeric = district.value.districtFaxNumber.replaceAll(/\D/g, '');
			let mask = createPhoneInputMask(numeric);
			district.value.districtFaxNumber = numericStringMask(numeric, mask);
		})


		watch(() => district.value.districtCdn, () => {
			const numeric = district.value.districtCdn.replaceAll(/\D/g, '');
			let mask = createCdnInputMask(numeric);
			district.value.districtCdn = numericStringMask(numeric, mask);
		})


		const getDistrict = () => {
			let get_uri = API_URL + "/district/displayById?_idDistrict=" + props.idDistrict;
			axios.get(get_uri, {
				params: {
					showUserCount: true
				}
			}).then((result) => {
				district.value = result.data;
				oldDistrictVals.value = {...district.value};
			})
		}


		const districtExists = () => {
			let get_uri = API_URL + "/district/exists?_districtCdn=" + district.value.districtCdn;

			axios.get(get_uri, {
				params: {
					showUserCount: true
				}
			})
				.then((result) => {
					exists.value = result.data.objectExists;
					message.value["existsMessage"] = !props.editingExisting ? result.data.message : null;
				})

			if (!exists.value && props.editingExisting) {
				delete message.value["existsMessage"];
			}

		}

		const showFullForm = computed(() => {
			let valid_cdn_length = false;

			if (district.value.districtCdn) {
				valid_cdn_length = district.value.districtCdn.length === 11;
				if (valid_cdn_length) {
					districtExists();
				} else {
					delete message.value["existsMessage"];
				}
			}
			return valid_cdn_length && (!exists.value || props.editingExisting);
		})


		function changeEsu(event) {
			district.value.idEsu = parseInt(event);
			context.emit("idEsu", district.value.idEsu);
		}

		const canSubmit = computed(() => {
			// let valid_cdn = CDN_REGEX.test(district.value.districtCdn);
			let valid_zip = ZIP_CODE_REGEX.test(district.value.districtZipCode) && district.value.districtZipCode.length === 5;
			let valid_name = district.value.districtName !== "";
			let valid_address = district.value.primaryDistrictAddress !== "";
      let valid_city = district.value.districtCity !== "";
			let valid_phone = district.value.districtPhoneNumber !== "" && district.value.districtPhoneNumber !== undefined
				&& district.value.districtPhoneNumber !== null;
      let valid_esu = district.value.idEsu !== null && district.value.idEsu !== undefined && district.value.idEsu !== -1;
			let valid_phone_length = false;

			if (valid_phone) {
				valid_phone_length = district.value.districtPhoneNumber.length === 14;
			}
			//  Phone number regex

			let ret_val = false;

			if (!props.editingExisting) {
				ret_val = valid_zip && valid_name && valid_address && valid_phone_length && valid_city && valid_esu;
			} else {
				ret_val = !isEqual(district.value, oldDistrictVals.value) && valid_zip && valid_name && valid_address && valid_city && valid_esu;
			}

			return ret_val;
		})

		function submitForm() {
			let post_body = {
				idDistrict: props.idDistrict,
				districtName: district.value.districtName,
				primaryDistrictAddress: district.value.primaryDistrictAddress,
				secondaryDistrictAddress: district.value.secondaryDistrictAddress,
				districtPhoneNumber: district.value.districtPhoneNumber,
				districtCdn: district.value.districtCdn,
				idEsu: district.value.idEsu,
				districtZipCode: district.value.districtZipCode,
				districtCity: district.value.districtCity,
				districtFaxNumber: district.value.districtFaxNumber
			}

			if (props.editingExisting) {
				submitEditDistrict(post_body);
			} else {
				submitCreateDistrict(post_body);
			}
		}

		async function submitCreateDistrict(postBody) {
			let post_uri = API_URL + "/district/createDistrict";
			await axios.post(post_uri, postBody, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				Swal.fire({
					title: "Created District",
					text: "Successfully Created District With Name: " + district.value.districtName,
					icon: 'success'
				}).then(function () {
					location.reload();
				})
			})
		}

		async function submitEditDistrict(postBody) {
			let post_uri = API_URL + "/district/updateDistrict";
			await axios.post(post_uri, postBody, {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				Swal.fire({
					title: "Updated District",
					text: "Successfully Updated " + district.value.districtName,
					icon: 'success'
				}).then(function () {
					location.reload();
				})
			})
		}

		function deleteHelper() {
			Swal.fire({
				title: 'Are You Sure?',
				text: `This Cannot Be Reversed ${district.value.districtName} will be deleted.`,
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}).then((result) => {
				if (result.isConfirmed) {
					deleteDistrictCall();
				}
			})
		}

		async function deleteDistrictCall() {
			await axios.delete(deleteDistrict(props.idDistrict), {
				headers: {
					"Authorization": "Bearer " + authToken.value
				}
			}).then((result) => {
				if (result.status === 200) {
					router.push({name: 'OrganizationList'})
				}
			})
		}

		const routeToMerge = () => {
			router.push({
				name: 'OrganizationMerge',
				params: {idRemovingDistrict: props.idDistrict, idEsu: district.value.idEsu}
			})
		}

		const canDelete = computed(() => {
			return district.value.userCount === 0 && district.value.schools.length === 0;
		})

		const dangerZoneMessage = computed(() => {
			return canDelete.value ? `Deleting a district is an irreversible action. Users will not be able to register for Project Para under this district.`
				: `There are schools and/or users linked to this district.
          You can transfer the users or merge the district,
          but this district cannot be deleted. Merging a district is an irreversible action. `
		})

		const esuDisabled = computed(() => {
			if (props.editingExisting) {
				return false;
			}
			return !showFullForm.value;
		})

		return {
			district,
			canSubmit,
			showFullForm,
			message,
			oldDistrictVals,
			canDelete,
			showDeleteModal,
			dangerZoneMessage,
			esuDisabled,
			deleteHelper,
			changeEsu,
			submitForm,
			routeToMerge
		}
	}
}
</script>

<style scoped>
span {
    color: red;
}

input {
    height: 41px;
}
</style>