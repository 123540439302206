import {API_URL} from "../../../Constants";

export const getDistrict = (idDistrict) => {
    return `${API_URL}/district/byId?_idDistrict=${idDistrict}`;
}

export const displayDistrictById = (idDistrict) => {
    return `${API_URL}/district/displayById?_idDistrict=${idDistrict}`;

}

export const deleteDistrict = (idDistrict) => {
    return `${API_URL}/districts/delete/${idDistrict}`;
}

export const mergeDistricts = (idKeepingDist, idRemovingDist) => {
    return `${API_URL}/districts/merge/keeping/${idKeepingDist}/removing/${idRemovingDist}`;
}

export const searchDistricts = () => {
    return `${API_URL}/districts/search`;
}

export const getDistrictsByMultipleEsus = () => {
    return `${API_URL}/districts/esu`
}

export const allDistrictsDisplay = () => {
    return `${API_URL}/districts/allForDisplay`;
}

export const districtsByEsu = (idEsu) => {
    return `${API_URL}/districts/byEsu?_idEsu=${idEsu}`
}